<template>
  <div class="settings-page">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="stethoscope" title="Configurações" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <form v-if="!isLoading" @submit.prevent>
            <div class="row">
              <div class="col-sm-12 col-lg-6 col-xl-4">
                <app-input
                  v-for="(field, index) in parameters"
                  :key="field.id"
                  v-model="parameters[index].value"
                  :label="field.label"
                ></app-input>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <b-button
                  @click="onSubmit()"
                  :disabled="isSaving"
                  :loading="isSaving"
                  type="is-primary"
                >
                  Salvar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ParametersService from '@/services/parameters.service';

export default {
  data: () => ({
    isLoading: false,
    isSaving: false,
    parameters: [],
  }),
  methods: {
    onSubmit() {
      this.isSaving = true;

      ParametersService.store(this.parameters)
        .then(() =>
          this.$buefy.snackbar.open('Configurações atualizadas com sucesso.')
        )
        .catch(() =>
          this.$buefy.snackbar.open('Erro ao atualizar configurações.')
        )
        .finally(() => (this.isSaving = false));
    },
    load() {
      this.isLoading = true;

      ParametersService.get()
        .then(({ data }) => (this.parameters = data))
        .catch(() =>
          this.$buefy.snackbar.open('Erro ao carregar configurações.')
        )
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.load();
  },
};
</script>
